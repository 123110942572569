<style lang="less" scoped>
.formWrap {
    width: 500px;
    padding: 30px 50px;
    border-radius: 10px;
    box-shadow: 0 0 10px #333;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background: rgba(55, 146, 161, 0.8);
}
.formItem {
    >div {
        color: #FFF;
    }
    >input,textarea {
        width: 100%;
        padding: 5px;   
        box-sizing: border-box;
    }
    margin: 10px 0;
}
</style>

<template>
    <div>
        <div class="formWrap">
            <h1 class="ju whiteColor">contact us</h1>
            <div class="formItem">
                <div>Name</div>
                <input type="text" v-model="name">
            </div>

            <div class="formItem">
                <div>Phone</div>
                <input type="text" v-model="phone">
            </div>

            <div class="formItem">
                <div>Title</div>
                <input type="text" v-model="title">
            </div>

            <div class="formItem">
                <div>Content</div>
                <textarea style="height: 100px;" v-model="content"></textarea>
            </div>
            <div class="blueBtn" @click="sub">Submit</div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            name:"",
            phone: '',
            titpe: "",
            content: '',
        }
    },
    methods:{
        sub () {
            if (!this.name || !this.phone || !this.title || !this.content) {
                this.$message.warning('Please complete')
            } 
            else {
                window.http({
                    url: "enquiry",
                    method: "POST",
                    data :{
                        customor_name: this.name,
                        phone: this.phone,
                        title: this.title,
                        content: this.content,
                    }
                }).then(res => {
                    console.log(res)
                    this.$message.success('send successfully')
                    this.$router.back()
                })
            }
        }
    }
}
</script>

